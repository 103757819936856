import styled from "styled-components"

export const Container = styled.button`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 3;
  background: none;
  border: none;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease 0.1s;
  transform: translateY(40px);
  width: 40px;
  height: 40px;
  cursor: pointer;
  visibility: hidden;
  svg {
    max-width: 100%;
    max-height: 100%;
    circle {
      transition: 0.2s fill ease;
    }
  }
  &:hover {
    svg {
      circle {
        fill: ${({ theme }) => theme.colors.button_hover};
      }
    }
  }
  &.active {
    opacity: 1;
    transition: opacity 0.2s ease, transform 0.2s ease 0.1s;
    transform: translateY(0);
    visibility: visible;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 40px;
    height: 40px;
    right: 34px;
    bottom: 40px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 44px;
    height: 44px;
    right: 24px;
    bottom: 64px;
  }
`

import styled from "styled-components"

export const HashHeader = styled.span`
  font-family: "Caveat", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: ${({ theme, color }) => (color ? color : "#0C0C0C")};
  text-align: center;
  position: relative;
  &:before {
    content: "#";
    color: #d83990;
    font-family: "Caveat", sans-serif;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 20px;
  }
`

export const Header = styled.h2`
  font-size: 40px;
  font-weight: 700;
  color: ${({ color }) => (color ? color : "#000")};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 20px;
  }
`

export const PageHeaderTitle = styled.h1`
  font-size: 36px;
  color: ${({ color }) => (color ? color : ({ theme }) => theme.colors.white)};
  padding: 0 40px;
  position: relative;
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 24px;
  }
`

export const SubHeader = styled.h3`
  font-size: 24px;
  font-weight: 700;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 18px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16px;
  }
`

export const SubHeaderTitle = styled.h2`
  font-size: ${({ size }) => (size ? size : "30px")};
  font-weight: 700;
  color: ${({ color }) => (color ? color : "")};
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 20px;
  }
`

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 14px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 12px;
  }
`

export const SubHeaderParagraph = styled(Paragraph)`
  text-align: center !important;
  margin-top: 30px;
  font-size: ${({ size }) => (size ? size : "")};
  color: ${({ color }) => (color ? color : "")};
`

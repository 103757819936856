import styled, { keyframes } from "styled-components"

const animations = {
  circle: keyframes`
  from { transform: rotate(0deg) translateX(3px) rotate(0deg); }
     to   { transform: rotate(360deg) translateX(3px) rotate(-360deg); }
 `,
  leftRight: keyframes`
0% {
  transform: translateX(0%);

}
50% {
  transform: translateX(-100%);
}

100% {
  transform: translateX(0%);
}
`,
  upDown: keyframes`
0% {
  transform: translateY(0%);

}
50% {
  transform: translateY(-100%);
}

100% {
  transform: translateY(0%);
}
`,
}

const getAnimation = type => animations[type]

export const Svg = styled.img`
  transform: ${({ rotation }) => (rotation ? `rotate(${rotation})` : "")};
  max-width: 100%;
  max-height: 100%;
`
export const SvgWrapper = styled.div`
  display: flex;
  height: 25px;
  width: 25px;
  position: absolute;
  top: ${({ top }) => (top ? top : "")};
  right: ${({ right }) => (right ? right : "")};
  left: ${({ left }) => (left ? left : "")};
  bottom: ${({ bottom }) => (bottom ? bottom : "")};
  transform: ${({ rotation }) => (rotation ? `rotate(${rotation})` : "")};
  animation: ${({ type }) => (type ? getAnimation(type) : animations["circle"])}
    ${({ duration }) => (duration ? duration : "1s")} infinite;
  animation-timing-function: linear;
  z-index: 2;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    display: none;
  }
`

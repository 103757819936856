import LogoImage
  from "assets/img/logo.svg";
import LogoText
  from "assets/img/logo-text.svg"
import HeartIcon
  from "assets/img/svg/heart-mobile-menu.svg"
import {
  PrimaryButton
} from "components/Buttons"
import {
  Socials
} from "components/Socials"
import {
  MENU_DATA
} from "data/menu"
import React, {
  useState
} from "react"
import uuid
  from "react-uuid"
import {
  ExternalLink, LogoTextSvg
} from './styles';
import {
  Container,
  Hamburger,
  HamburgerSpan,
  Logo,
  LogoWrapper,
  MenuLink,
  MenuList,
  MenuListItem,
  MenuWrapper,
  MobileHeart,
  MobileHeartWrapper,
  MobileWrapper,
  RightBox,
  StyledAnchorLink,
  Wrapper,
} from "./styles"

export const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false)
  let window_width = null
  const isBrowser = typeof window !== `undefined`
  if (isBrowser) {
    window_width = window.innerWidth
  }

  const handleClick = () => {
    if (!menuActive) {
      setMenuActive(true)
      if (isBrowser) {
        document.body.classList.add("blocked")
      }
    } else {
      setMenuActive(false)
      if (isBrowser) {
        document.body.classList.remove("blocked")
      }
    }
  }

  const closeMobileMenu = () => {
    if (window_width < 1081) {
      setMenuActive(false)
      if (isBrowser) {
        document.body.classList.remove("blocked")
      }
    }
  }

  return (
    <Container className={menuActive ? "active" : ""}>
      <Wrapper>
        <LogoWrapper
          onClick={() => closeMobileMenu()}
          to="/"
          title="Logo - fundacja świat bez barier"
        >
          <Logo src={LogoImage} alt="Logo - fundacja świat bez barier"/>
            <LogoTextSvg src={LogoText} alt="Logo - fundacja świat bez barier"/>
        </LogoWrapper>
        <MenuWrapper className={menuActive ? "active" : ""}>
          <MenuList>
            <MenuListItem>
            </MenuListItem>
            {MENU_DATA.map(item => (
              <MenuListItem key={uuid()}>
                {item.anchorLink ? (
                  <StyledAnchorLink
                    onAnchorLinkClick={() => closeMobileMenu()}
                    to={item.to}
                    title={item.title}
                  >
                    {item.title}
                  </StyledAnchorLink>
                ) : (
                   <MenuLink
                     onClick={() => closeMobileMenu()}
                     to={item.to}
                     title={item.title}
                   >
                     {item.title}
                   </MenuLink>
                 )}
              </MenuListItem>
            ))}
          </MenuList>
          <RightBox>
            <Socials/>
            <PrimaryButton
              onClick={() => closeMobileMenu()}
              type={"heart navbar-button"}
              href="https://wsparcie.fundacjaswiatbezbarier.pl/"
            >
              Wesprzyj nas
            </PrimaryButton>
          </RightBox>
        </MenuWrapper>
        <MobileWrapper>
          <MobileHeartWrapper
            href="https://wsparcie.fundacjaswiatbezbarier.pl"
            onClick={() => closeMobileMenu()}
          >
            <MobileHeart src={HeartIcon} alt="Heart Icon"/>
          </MobileHeartWrapper>
          <Hamburger
            className={menuActive ? "active" : ""}
            onClick={() => handleClick()}
          >
            <HamburgerSpan/>
            <HamburgerSpan/>
            <HamburgerSpan/>
          </Hamburger>
        </MobileWrapper>
      </Wrapper>
    </Container>
  )
}

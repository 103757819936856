import styled from "styled-components"

import CookieConsent from "react-cookie-consent"

import CookiesImage from "../../assets/img/svg/cookies.svg"
import CookiesClose from "../../assets/img/svg/cookies_close.svg"
import CookiesCloseHover from "../../assets/img/svg/cookies_close_pink.svg"

export const Box = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  display: flex;
  align-items: center;

  .CookieConsent {
    flex: auto !important;
    display: flex !important;
    align-items: center !important;
    position: relative !important;
    background-color: ${({ theme }) => theme.colors.primary} !important;
    padding: 24px 48px;
    box-shadow: 0px -5px 15px -4px rgba(255, 255, 255, 0.35) !important;

    .cookies-wrapper {
      max-width: 1410px;
      width: 100%;
      margin: 0 auto !important;
    }
    .center-button {
      button {
        font-size: 14px;
        color: #fff !important;
        white-space: nowrap;
        transition: 0.2s all ease;
        background-color: transparent !important;
        border: none !important;
        padding-right: 36px !important;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 100%;
        background: transparent url(${CookiesClose}) no-repeat 100% 50% !important;
        background-size: 14px;
        &:hover {
          color: ${({ theme }) => theme.colors.accentColor} !important;
          background: transparent url(${CookiesCloseHover}) no-repeat 100% 50% !important;
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    .CookieConsent {
      flex-direction: column !important;
      padding: 24px 24px 64px 24px;
      .cookies-wrapper {
        flex: auto !important;
      }
      .center-button {
        button {
          font-size: 12px !important;
          background-size: 12px !important;
        }
      }
    }
  }
`
export const Container = styled(CookieConsent)``

export const Wrapper = styled.div`
  max-width: 640px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 1023px) {
    max-width: 1600px;
    flex-direction: row;
  }
`

export const TextBox = styled.div`
  color: #fff;
  position: relative;
  padding-left: 64px;
  text-align: left;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 46px;
    height: 46px;
    display: block;
    background-image: url(${CookiesImage});
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 0;
    text-align: center;
    &:before {
      display: none;
    }
  }
`

export const Title = styled.h5`
  font-size: 18px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
`

export const Text = styled.p`
  font-weight: 300;
  font-size: 14px;
  margin-top: 12px;
  line-height: 1.4;
  padding-right: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 10px;
    padding-right: 0;
  }
`

export const CloseCookies = styled.div`
  margin-top: 0;
  margin-left: 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 24px;
  }
`

export const CloseCookiesText = styled.span`
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  transition: 0.2s all ease;

  @media screen and (min-width: 1023px) {
    font-size: 14px;
  }
`

export const CloseCookiesImage = styled.span`
  width: 14px;
  height: 14px;
  background-image: url(${CookiesClose});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 12px;
  transition: 0.2s all ease;
`

export const CloseCookiesButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  @media screen and (min-width: 1023px) {
    &:hover {
      ${CloseCookiesImage} {
        background-image: url(${CookiesCloseHover});
      }
      ${CloseCookiesText} {
        color: ${({ theme }) => theme.colors.accentColor};
      }
    }
  }
`

import styled from "styled-components"

export const GlobalWrapper = styled.div`
  max-width: 100vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`
export const SectionWrapper = styled.section`
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 1030px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 836px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    max-width: 683px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_small}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

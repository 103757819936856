import React from "react"
import { Svg, SvgWrapper } from "./styles"

const TitleSvg = ({
  image,
  top,
  right,
  bottom,
  left,
  type,
  duration,
  rotation,
}) => {
  return (
    <SvgWrapper
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      type={type}
      duration={duration}
    >
      <Svg src={image} rotation={rotation} alt="ikona" />
    </SvgWrapper>
  )
}

export { TitleSvg }

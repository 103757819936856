import styled, {css, keyframes} from "styled-components"
import {Link} from "gatsby"
import {AnchorLink} from "gatsby-plugin-anchor-links"

const pulse = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05);
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  transition: 0.2s background-color ease;
`
export const Wrapper = styled.div`
  max-width: 2560px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;
  margin: 0 auto;
  
  @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
    height: 60px;
    padding: 0 36px;
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    padding: 0 24px;
  }
`

export const LogoWrapper = styled(Link)`
  display: inline-flex;
  align-items: center;

  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    position: relative;
    z-index: 120;
  }
`

export const Logo = styled.img`
  width: auto;
  height: 50px;
  flex-shrink: 0;
  margin-right: 12px;
  
  @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
    height: 38px;
  }
`

export const LogoTextSvg = styled.img`
  width: auto;
  height: 24px;
  flex-shrink: 0;
  
  @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
    height: 20px;
  }
  
  @media screen and (max-width: 1100px) {
    display: none;
  }
  
   @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    display: block;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #5618a4;
    flex-direction: column;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all ease;
    padding-top: 64px;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
`

export const MenuList = styled.ul`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    flex-direction: column;
    margin-right: 0;
    margin: 0 0 64px 0;
  }
`

export const MenuListItem = styled.li`
  &:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`

const defaultLink = css`
  font-weight: 700;
  font-size: 14px;
  transition: 0.2s color ease;

  &:hover {
    color: ${({theme}) => theme.colors.accentColor};
  }

  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    color: #fff;
    font-size: 24px;
  }
`

export const MenuLink = styled(Link)`
  ${defaultLink}
`

export const StyledAnchorLink = styled(AnchorLink)`
  ${defaultLink}
`

export const ExternalLink = styled.a`
  ${defaultLink}
`;

export const RightBox = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    flex-direction: column-reverse;
    margin-bottom: 80px;
  }
`

export const Hamburger = styled.div`
  width: 24px;
  height: 18px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s all ease;
  z-index: 100;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${({theme}) => theme.colors.accentColor};
    transition: 0.2s all ease;
  }

  &.active {
    transform: rotate(45deg);

    span {
      &:nth-child(1) {
        transform: translateY(8px) rotate(90deg);
      }

      &:nth-child(2) {
      }

      &:nth-child(3) {
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    display: flex;
    position: relative;
    z-index: 120;
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
    width: 22px;
    height: 14px;
    &.active {
      transform: rotate(45deg);

      span {
        &:nth-child(1) {
          transform: translateY(6px) rotate(90deg);
        }
      }
    }
  }
`

export const HamburgerSpan = styled.span``

export const MobileWrapper = styled.div`
  display: none;
  align-items: center;
  height: 100%;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    display: flex;
  }
`

export const MobileHeartWrapper = styled.a`
  width: 38px;
  height: 38px;
  animation: ${pulse} 0.5s ease-in-out infinite alternate;
  margin-right: 30px;

  @media screen and (max-width: ${({theme}) =>
    theme.breakpoints.phone_small}) {
    margin-right: 15px;
  }
`

export const MobileHeart = styled.img`
  max-width: 100%;
  max-height: 100%;
`

import React from "react"

import { InsideLink, OutsideLink, Button } from "./styles"

export const PrimaryButton = ({ to, href, type, children, ...otherProps }) => {
  return (
    <>
      {to && (
        <InsideLink className={type} to={to} {...otherProps}>
          {children}
        </InsideLink>
      )}
      {href && (
        <OutsideLink className={type} href={href} {...otherProps}>
          {children}
        </OutsideLink>
      )}
      {!href && !to && (
        <Button className={type} {...otherProps}>
          {children}
        </Button>
      )}
    </>
  )
}

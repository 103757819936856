import React from "react"
import { Box, BoxHref, BoxIcon, Icon, BoxTitle } from "./styles"

const FooterBox = ({ box }) => {
  return (
    <>
      {box.to && (
        <Box to={box.to} color={box.color}>
          <BoxIcon>
            <Icon src={box.icon} />
          </BoxIcon>
          <BoxTitle>{box.title}</BoxTitle>
        </Box>
      )}
      {box.href && (
        <BoxHref
          href={box.href}
          rel="noopener"
          color={box.color}
          target={box.target}
        >
          <BoxIcon>
            <Icon src={box.icon} alt="" />
          </BoxIcon>
          <BoxTitle>{box.title}</BoxTitle>
        </BoxHref>
      )}
    </>
  )
}

export { FooterBox }

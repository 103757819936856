import styled, { css, keyframes } from "styled-components"
import { Link } from "gatsby"

// icons
import HeartIcon from "assets/img/svg/heart.svg"
import RightArrow from "assets/img/svg/arr_right.svg"

// types
// heart, right_arrow

const pulse = keyframes`
  from {
    transform: translateY(-50%) scale(1);
  }

  to {
    transform: translateY(-50%) scale(1.15);
  }
`

const defaultStyles = css`
  font-size: 14px;
  font-weight: 700;
  border-radius: 65px;
  background-color: #d83990;
  transition: 0.2s background-color ease;
  padding: 18px 36px;
  color: #fff;
  font-weight: 700;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colors.button_hover};
  }
  &.navbar-button {
    padding: 12px 36px;
    &.heart {
      padding: 13px 24px 13px 40px;
      &:before {
        width: 12px;
        height: 12px;
        left: 18px;
      }
    }
  }
  &.heart {
    padding-left: 64px;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(1);
      left: 24px;
      width: 18px;
      height: 18px;
      background-image: url(${HeartIcon});
      background-size: contain;
      background-repeat: no-repeat;
      animation: ${pulse} 0.6s ease-in-out infinite alternate;
      transform-origin: center;
    }
  }
  &.right_arrow {
    padding-right: 64px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
      width: 7px;
      height: 10px;
      background-image: url(${RightArrow});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &.heart_with_arrow {
    padding-left: 52px;
    padding-right: 52px;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(1);
      left: 18px;
      width: 18px;
      height: 18px;
      background-image: url(${HeartIcon});
      background-size: contain;
      background-repeat: no-repeat;
      animation: ${pulse} 1s ease infinite alternate;
      transform-origin: center;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 18px;
      width: 7px;
      height: 10px;
      background-image: url(${RightArrow});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 14px 36px;
    font-size: 12px;
    &.navbar-button {
      padding: 10px 24px;
      &.heart {
        padding: 10px 24px 10px 40px;
      }
    }
    &.heart {
      padding-left: 38px;
      &:before {
        left: 14px;
        width: 14px;
        height: 14px;
      }
    }
  }
`

export const InsideLink = styled(Link)`
  ${defaultStyles}
`

export const OutsideLink = styled.a`
  ${defaultStyles}
`
export const Button = styled.button`
  ${defaultStyles}
`

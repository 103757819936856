import styled, { css } from "styled-components"

const IconColor = css`
  fill: ${props => (props.color ? props.color : "#989898")};
`

const IconStroke = css`
  stroke: ${props => (props.color ? props.color : props.theme.colors.primary)};
`

export const IconWrapper = styled.svg`
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "auto")};
  position: relative;
`
export const IconPath = styled.path`
  ${IconColor}
`
export const IconCircle = styled.circle`
  ${IconColor}
`
export const IconPolygon = styled.polygon`
  ${IconColor}
`

export const IconRect = styled.rect`
  ${IconColor}
`

export const IconPathStroke = styled.path`
  ${IconStroke}
`
export const IconCircleStroke = styled.circle`
  ${IconStroke}
`
export const IconPolygonStroke = styled.polygon`
  ${IconStroke}
`

export const IconRectStroke = styled.rect`
  ${IconStroke}
`

export const defaultTheme = {
  colors: {
    primary: "#6121B2",
    accentColor: "#D83990",
    accentBlue: "#6EE7FC",
    fontPrimary: "#ffffff",
    fontSecondary: "#000",
    purple: "#5618A4",
    lightGrayBackground: "#F6F6F6",
    white: "#fff",
    black: "#000",
    button_hover: "#C23381",
    red: '#ec1414',
  },
  breakpoints: {
    desktop: "1601px",
    laptop: "1470px",
    laptop_small: "1281px",
    tablet: "1080px",
    tablet_small: "869px",
    phone: "601px",
    phone_small: "430px",
  },
}

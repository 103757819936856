import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"
export const ChevronLeft = ({ color = "#fff" }) => {
  return (
    <IconWrapper
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M6.34643 5.92724e-05C6.69744 -0.00214088 6.98108 0.109735 7.21756 0.327514C7.36106 0.459501 7.50321 0.59306 7.64537 0.726933C8.11798 1.17223 8.119 1.87617 7.64368 2.32021C6.36698 3.51344 5.08893 4.70509 3.81155 5.89738C3.79572 5.91215 3.78056 5.92786 3.76372 5.94138C3.71218 5.9816 3.72027 6.01648 3.76541 6.05828C3.98268 6.25846 4.19793 6.46052 4.41386 6.66196C5.48137 7.65815 6.54821 8.65497 7.61674 9.65053C7.87174 9.8878 8.00615 10.1703 7.99638 10.5078C7.98829 10.7875 7.8842 11.0345 7.67737 11.2382C7.51736 11.3956 7.35297 11.549 7.18488 11.6989C6.72001 12.1137 5.96881 12.0983 5.50091 11.663C4.56612 10.7932 3.63369 9.92111 2.70025 9.0503C1.92749 8.32909 1.15642 7.60599 0.380626 6.8876C0.0390492 6.57146 -0.0428081 6.18838 0.0188379 5.76319C0.0575766 5.49607 0.199058 5.28081 0.402185 5.09163C2.09794 3.51187 3.79202 1.93022 5.48575 0.348568C5.72863 0.120733 6.01698 -0.00308306 6.34643 5.92724e-05Z"
        color={color}
      />
    </IconWrapper>
  )
}

export const MENU_DATA = [
  {
    title: "Statut Fundacji",
    to: "/statut-fundacji.pdf",
    outsideLink: true,
  },
  {
    title: "O nas",
    to: "/o-nas",
  },
  {
    title: "Podopieczni",
    to: "/podopieczni",
  },
  {
    title: "Usługi",
    to: "/uslugi",
  },
  {
    title: "Patroni",
    to: "/#patroni",
    anchorLink: true,
  },
  {
    title: "Aktualności",
    to: "/aktualnosci",
  },
  {
    title: "Regulamin",
    to: "/regulamin.pdf",
    outsideLink: true,
  },
  {
    title: "Kontakt",
    to: "/kontakt",
  },
]

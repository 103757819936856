import React from "react"
import { IconWrapper, IconPath, IconCircle } from "shared/Styles/Icons"
import styled from "styled-components"

const StyledIconWrapper = styled(IconWrapper)`
  overflow: hidden;
  position: relative;
  ${IconCircle} {
    transition: fill 0.2s ease-in;
  }
  &:hover {
    ${IconCircle} {
      fill: ${p => p.theme.colors.accentColor};
    }
  }
`

export const Facebook = ({ color = "#ffffff", background = "#242424" }) => {
  return (
    <StyledIconWrapper
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconCircle cx="25" cy="25" r="25" color={background} />
      <IconPath
        d="M26.62 29.5173C26.62 30.917 26.6185 32.3166 26.623 33.7163C26.6233 33.8281 26.5884 33.8652 26.4931 33.875C26.2162 33.9032 25.9404 33.9489 25.6627 33.9638C25.2383 33.9864 24.8124 34.0107 24.3884 33.995C23.9551 33.979 23.5237 33.9141 23.0912 33.8738C22.9956 33.8648 22.968 33.8207 22.968 33.7182C22.9706 30.922 22.9702 28.1263 22.9702 25.3301C22.9702 25.1721 22.9702 25.1721 22.8191 25.1721C21.9192 25.1721 21.0192 25.1706 20.1193 25.1745C20.027 25.1749 19.9998 25.1495 20.0001 25.0498C20.0034 23.93 20.0031 22.8103 20.0005 21.6906C20.0001 21.6003 20.0244 21.5752 20.1086 21.5752C21.0056 21.5784 21.9026 21.5772 22.7992 21.5772C22.9702 21.5772 22.9699 21.5772 22.9702 21.3997C22.9732 20.4004 22.9563 19.4007 22.9857 18.4022C23.0133 17.4752 23.2402 16.5994 23.7359 15.8195C24.1707 15.1353 24.7591 14.6567 25.4784 14.3569C25.8992 14.1813 26.3365 14.084 26.7862 14.0347C27.371 13.9702 27.9535 14.0058 28.5357 14.059C28.941 14.0957 29.3445 14.1532 29.7483 14.2028C29.8148 14.211 29.8818 14.2224 29.9469 14.24C29.9667 14.2454 29.987 14.2728 29.9954 14.2951C30.0039 14.3178 29.9984 14.3463 29.9984 14.3725C29.9984 15.3065 29.9984 16.2409 29.9984 17.1749C29.9984 17.3059 29.998 17.3055 29.8781 17.3063C29.3518 17.3102 28.8252 17.3047 28.2989 17.3216C27.8866 17.3348 27.5041 17.4596 27.182 17.75C26.9253 17.9815 26.7616 18.2775 26.6958 18.6231C26.6487 18.8698 26.6263 19.1251 26.6226 19.3772C26.613 20.0673 26.6193 20.7574 26.6193 21.4474C26.6193 21.5768 26.6196 21.5768 26.7395 21.5772C27.7373 21.5772 28.7354 21.5772 29.7332 21.5772C29.8604 21.5772 29.8638 21.5784 29.8443 21.7148C29.777 22.1809 29.706 22.6461 29.6387 23.1117C29.5714 23.5778 29.507 24.0442 29.4415 24.5102C29.4162 24.6901 29.3879 24.8699 29.3654 25.0501C29.3544 25.1389 29.3213 25.1745 29.2297 25.1741C28.4 25.1702 27.5707 25.1717 26.741 25.1717C26.6193 25.1717 26.6193 25.1721 26.6193 25.2984C26.62 26.7051 26.62 28.111 26.62 29.5173Z"
        color={color}
      />
    </StyledIconWrapper>
  )
}

import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"
import styled, { keyframes } from "styled-components"

const anim = keyframes`
 0% {
  transform: scale(0.9);
  }
  100% {
  transform: scale(1);

  }
`

const Container = styled.div`
  animation: ${anim} 1s ease infinite alternate;
  transform-origin: center;
`

const Heart = ({ color, width = "20", height = "20" }) => {
  return (
    <Container>
      <IconWrapper
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <IconPath
          d="M19.9949 6.35416C20.0365 7.33266 19.8384 8.26838 19.5334 9.18316C19.114 10.4421 18.4739 11.575 17.7211 12.6414C16.7588 14.0045 15.6409 15.216 14.4448 16.3508C13.0719 17.6529 11.6105 18.8379 10.1014 19.9587C10.0312 20.0108 9.98254 20.0155 9.90828 19.9601C8.29665 18.7631 6.7385 17.4953 5.2882 16.0857C4.13274 14.963 3.05419 13.765 2.13389 12.4168C1.48367 11.4648 0.929366 10.4574 0.537287 9.35756C0.352519 8.83994 0.215933 8.30791 0.11736 7.76285C-0.0192264 7.00572 -0.0267409 6.24162 0.0466358 5.48449C0.195157 3.95814 0.714982 2.59178 1.76126 1.48539C2.33104 0.883128 3.00115 0.445966 3.77824 0.204597C4.37718 0.0185702 4.99514 -0.00654341 5.61486 0.0083387C6.39991 0.0274064 7.15489 0.184599 7.8555 0.573394C8.48893 0.924519 9.01052 1.42214 9.48835 1.97464C9.63422 2.14346 9.78009 2.31274 9.91756 2.489C9.98254 2.57225 10.017 2.56713 10.0855 2.48993C10.4228 2.10718 10.7534 1.71653 11.1106 1.35563C11.6154 0.845923 12.2082 0.47759 12.8778 0.253894C13.2902 0.116234 13.715 0.0436837 14.1447 0.017175C14.7229 -0.0186351 15.3019 -0.00468315 15.8712 0.110653C16.5763 0.253428 17.2084 0.577114 17.772 1.04125C18.6852 1.79373 19.2917 2.77083 19.6506 3.92419C19.8959 4.71388 20.0294 5.52123 19.9949 6.35416Z"
          color={color}
        />
      </IconWrapper>
    </Container>
  )
}
export { Heart }

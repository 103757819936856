import React from "react"

import { Container, SocialLink, Icon } from "./styles"

import FacebookIcon from "assets/img/svg/facebook.svg"
import InstagramIcon from "assets/img/svg/instagram.svg"

const data = [
  {
    title: "Facebook - świat bez barier",
    icon: FacebookIcon,
    href: "https://www.facebook.com/FundacjaSwiatBezBarier",
  },
  {
    title: "Instagram - świat bez barier",
    icon: InstagramIcon,
    href: "https://www.instagram.com/fundacjaswiatbezbarier/",
  },
]

export const Socials = () => {
  return (
    <Container>
      {data.map((item, i) => (
        <SocialLink
          key={i}
          href={item.href}
          target="_blank"
          rel="noopener"
          title={item.title}
        >
          <Icon src={item.icon} alt={item.title} />
        </SocialLink>
      ))}
    </Container>
  )
}

import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"
export const GrayHeart = ({ color = "#989898" }) => {
  return (
    <IconWrapper
      width="auto"
      height="100%"
      viewBox="0 0 125 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M120.173 29.772C122.184 29.2775 122.846 30.4669 123.414 32.132C127.604 44.345 123.283 58.5862 112.787 66.7491C107.055 71.2061 100.605 74.1045 93.5489 75.7821C84.9259 77.8291 77.0835 81.4223 71.0954 88.2268C67.9672 91.7824 65.4633 95.6572 66.1439 100.815C66.2313 101.479 65.3384 102.268 64.9014 103C64.1646 102.443 62.922 101.998 62.7722 101.316C60.2121 89.5226 51.8077 82.6179 42.3105 76.7023C35.8105 72.6522 28.9858 69.1216 22.5482 64.9713C11.6024 57.9039 3.82236 48.4202 1.42466 35.1806C1.34973 34.7799 1.33724 34.373 1.26855 33.7095C2.31755 33.5843 3.28537 33.4716 4.48423 33.3276C4.92755 35.1117 5.27722 36.8081 5.77674 38.4607C8.52411 47.5626 14.0251 54.7427 21.7614 60.026C27.737 64.1012 34.0746 67.6568 40.3249 71.3126C49.5348 76.7023 58.1329 82.7306 63.5964 92.7652C64.5642 91.2503 65.3072 89.9607 66.1689 88.7526C72.5253 79.826 81.6041 75.1749 91.8943 72.5645C98.1758 70.9683 104.114 68.7147 109.39 64.8649C119.761 57.2904 123.751 45.1901 119.949 32.9145C119.668 32.0131 119.793 30.9802 119.73 30.0099C119.88 29.9348 120.03 29.8534 120.173 29.772Z"
        color={color}
      />
      <IconPath
        d="M120.174 29.7719C120.024 29.8533 119.88 29.9347 119.73 30.0161C119.15 29.5528 118.388 29.2085 118.007 28.6139C110.72 17.1583 100.53 12.4258 87.6797 14.5855C75.916 16.5636 66.2003 25.8846 63.3342 37.9912C63.0033 39.3996 63.0533 40.9208 62.5725 42.2604C62.2478 43.1618 61.2925 43.8379 60.6181 44.6078C60.0936 43.6438 59.1882 42.7174 59.107 41.7158C58.7137 36.8957 59.182 31.9128 58.1642 27.2429C55.3794 14.4665 45.3577 5.3146 33.3754 3.79345C21.312 2.25978 11.871 7.51182 6.70723 18.6168C6.48869 19.0801 6.23893 19.5308 6.0079 19.9752C2.9733 18.7045 3.44785 16.8578 4.70914 14.5354C10.847 3.28014 24.3341 -2.21603 36.5474 0.832531C51.5018 4.55716 58.8073 14.8547 62.5413 29.4339C63.3218 28.1882 63.9274 27.2242 64.5393 26.2664C77.0024 6.78567 104.951 5.08925 118.581 22.9862C120.117 25.0019 121.216 27.1428 120.174 29.7719Z"
        color={color}
      />
      <IconPath
        d="M29.7723 8.44458C30.1657 8.82643 30.8775 9.52754 31.5955 10.2224C30.8213 10.8609 30.0907 11.9877 29.2665 12.0628C23.4034 12.5824 19.0326 15.3868 15.6608 20.1318C15.1363 20.8704 13.7376 20.9894 12.7448 21.39C12.7386 20.3634 12.339 19.0989 12.7885 18.354C16.4725 12.2694 22.0859 9.22706 29.7723 8.44458Z"
        color={color}
      />
      <IconPath
        d="M112.387 30.999C111.544 31.6312 110.676 32.2885 109.671 33.046C107.967 27.819 104.601 24.8017 99.1688 24.1632C98.4257 24.0756 97.8013 22.9801 97.127 22.3541C97.9949 21.8345 98.8503 20.883 99.7307 20.8705C105.294 20.7516 110.714 25.146 112.387 30.999Z"
        color={color}
      />
      <IconPath
        d="M4.16575 24.5826C3.27285 24.2383 2.50484 23.9378 1.56823 23.5747C1.91165 22.2602 2.21761 21.0958 2.52357 19.9252C2.86075 19.8564 3.20417 19.7875 3.54134 19.7124C3.97843 21.3149 4.65902 22.8987 4.79015 24.5262C4.93376 26.3541 4.64654 28.2384 4.39677 30.0725C4.3156 30.6547 3.78486 31.5749 3.39149 31.6124C2.83577 31.6625 2.03654 31.1742 1.66189 30.6734C0.275722 28.8017 1.11866 26.4918 3.42271 25.4903C3.67247 25.3901 3.80359 25.0458 4.16575 24.5826Z"
        color={color}
      />
    </IconWrapper>
  )
}

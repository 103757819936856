import React from "react"
import PropTypes from "prop-types"
import styled, {ThemeProvider} from "styled-components"
import {defaultTheme} from "shared/Themes"
import {ParallaxProvider} from "react-scroll-parallax"
import {Transition} from "components/Transition"
import {Navbar} from "components/Navbar"
import {Footer} from "components/Footer"
import {Cookies} from "components/Cookies"
import {Icon} from "../components/Icons";

const LayoutWrapper = styled.div`
    padding-top: 80px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding-top: 60px;
    }
`;

const MainLayout = ({children, location}) => {
  return (
    <ParallaxProvider>
      <ThemeProvider theme={defaultTheme}>
        <Navbar/>
        <LayoutWrapper>
          <Transition location={location}>{children}</Transition>
        </LayoutWrapper>
        <Footer/>
        <Icon type="scrollTop"/>
        <Cookies/>
      </ThemeProvider>
    </ParallaxProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;

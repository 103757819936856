import CircleSvg from 'assets/img/PageHeader/o.svg';
import PipeSvg from 'assets/img/PageHeader/pipe.svg';
import PlusSvg from 'assets/img/PageHeader/x.svg';
import FingerIcon from 'assets/img/svg/finger.svg';
import HeartIcon from 'assets/img/svg/heart_big.svg';
import LogoIvIco from 'assets/img/svg/logo_iv.svg';
import PigIcon from 'assets/img/svg/pig.svg';
import {Socials} from 'components/Socials';
import {TitleSvg} from 'components/TitleSvg';

import {HashHeader} from 'components/Typography';
import {MENU_DATA} from 'data/footerMenu';
import React from 'react';
import uuid from 'react-uuid';
import {FooterBox} from '../FooterBox';
import {BoxWrapper, Container, Copyrights, FooterBottom, FooterContact, FooterContactItem, FooterLine, FooterLineContainer, FooterMail, FooterMenu, FooterMenuAnchor, FooterMenuItem, FooterMenuLink, FooterMenuList, FooterOutsideLink, FooterPhone, FooterSpan, FooterTitle, LogoIv, LogoIvBox, MailTitle, StyledSpan, Title} from './styles';

const boxes = [
  {
    color: '#68CF72',
    icon: PigIcon,
    title: 'Wpłać',
    href: `${process.env.GATSBY_SUPPORT_PAGE}`,
    target: null,
  },
  {
    color: '#D83990',
    icon: HeartIcon,
    title: 'Wspieraj',
    href: '/wesprzyj-nas',
    target: null,
  },
  {
    color: '#509CED',
    icon: FingerIcon,
    title: 'Polub nas',
    href: 'https://www.facebook.com/FundacjaSwiatBezBarier',
    target: '_blank',
  },
];

const footer_data = [
  {
    title: 'Fundacja Świat Bez Barier',
    items: [
      [
        'Forma prawna: Fundacja',
        'NIP: 7952559066',
        'REGON: 386109396',
        'KRS: 0000842391',
      ],
    ],
  },
  {
    // title: "Oddziały",
    items: [
      [
        <b>Siedziba Przemyśl</b>,
        'ul. Księdza Piotra Skargi 1',
        '37-700 Przemyśl',
      ],
      [
        <b>Oddział Rzeszów</b>,
        'ul. Przemysłowa 14b',
        '35-105 Rzeszów',
      ],
    ],
  },
];

export const Footer = () => {
  return (
    <Container>
      <Title>
        Zróbmy <StyledSpan>RAZEM</StyledSpan> coś dobrego!
      </Title>
      <HashHeader>śwatbezbarier</HashHeader>
      <BoxWrapper>
        <TitleSvg image={PlusSvg} bottom="-30%" left="25%" duration="5s"/>
        <TitleSvg image={CircleSvg} bottom="45%" right="-5%" duration="4s"/>
        <TitleSvg
          image={PipeSvg}
          top="35%"
          left="-5%"
          duration="3s"
          rotation="80deg"
        />
        {boxes.map(box => (
          <FooterBox box={box} key={uuid()}/>
        ))}
      </BoxWrapper>

      <FooterContact>
        {footer_data.map(data => (
          <FooterContactItem key={uuid()}>
            <FooterLine>
              {
                data.title &&
                <FooterTitle>{data.title}</FooterTitle>

              }
              {data.items.map(item => (
                <FooterLineContainer key={uuid()}>
                  {
                    item.map((line) => (
                      <FooterSpan key={uuid()}>{line}</FooterSpan>
                    ))
                  }
                </FooterLineContainer>
              ))}
            </FooterLine>
          </FooterContactItem>
        ))}
        <FooterContactItem>
          <FooterLine>
            <FooterTitle>Kontakt</FooterTitle>
            <MailTitle>Biuro zarządu</MailTitle>
            <FooterMail
              href="mailto:biuro@fundacjaswiatbezbarier.pl"
              title="Mail kontaktowy do biura fundacji"
            >
              biuro@fundacjaswiatbezbarier.pl
            </FooterMail>
            <FooterPhone href="tel:+48791317069" title="Telefon kontaktowy">
              +48 791 317 069
            </FooterPhone>
          </FooterLine>
        </FooterContactItem>
        <FooterContactItem>
          <FooterLine>
            <FooterTitle>Znajdź nas na:</FooterTitle>
            <Socials/>
          </FooterLine>
        </FooterContactItem>
      </FooterContact>
      <FooterBottom>
        <FooterMenu>
          <FooterMenuList>
            {MENU_DATA.map(data => (
              <FooterMenuItem key={uuid()}>
                {data.anchorLink ? (
                  <FooterMenuAnchor to={data.to} title={data.title}>
                    {data.title}
                  </FooterMenuAnchor>
                ) : data.outsideLink ? (
                  <FooterOutsideLink
                    href={data.to}
                    title={data.title}
                    target="_blank"
                  >
                    {data.title}
                  </FooterOutsideLink>
                ) : (
                      <FooterMenuLink to={data.to}>{data.title}</FooterMenuLink>
                    )}
              </FooterMenuItem>
            ))}
          </FooterMenuList>
        </FooterMenu>
        <LogoIvBox
          href="https://interactivevision.pl/"
          target="_blank"
          rel="noopener"
          title="Realizacja InteractiveVision"
        >
          <LogoIv src={LogoIvIco} alt="Logo Interactivevision"/>
        </LogoIvBox>
      </FooterBottom>
      <Copyrights>
        Copyright © 2020 - 2021 | Fundacja świat bez barier - Wszystkie prawa
        zastrzeżone
      </Copyrights>
    </Container>
  );
};

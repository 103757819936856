import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"
const Message = ({ color }) => {
  return (
    <IconWrapper
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M12.4266 7.66769C12.254 7.78543 12.0773 7.89827 11.9079 8.02092C10.2525 9.22288 8.59318 10.4191 6.94583 11.6325C6.35835 12.0651 5.79804 12.1378 5.13863 11.7707C3.65993 10.9465 2.15805 10.1656 0.662565 9.37415C0.254923 9.15828 -0.028827 8.87701 0.00234553 8.36515C0.0327188 7.87047 0.340448 7.63498 0.749688 7.46654C5.0539 5.69876 9.35651 3.9277 13.6591 2.15665C15.3313 1.46899 17.0002 0.773982 18.6755 0.0945049C19.3022 -0.159788 19.8537 0.111676 19.9872 0.757628C20.0319 0.973491 19.956 1.23678 19.8737 1.45428C19.1271 3.44038 18.3678 5.42157 17.6116 7.40358C15.9827 11.671 14.3513 15.9375 12.7287 20.2065C12.5617 20.6472 12.3203 20.9776 11.8295 20.998C11.3524 21.0185 11.087 20.7094 10.8976 20.303C10.0751 18.5401 9.24541 16.7822 8.43013 15.016C8.17515 14.4641 8.27347 13.9432 8.61636 13.4469C9.84808 11.662 11.071 9.86965 12.2955 8.07979C12.3691 7.97186 12.4354 7.8582 12.5049 7.747C12.4794 7.72165 12.453 7.69467 12.4266 7.66769Z"
        color={color}
      />
    </IconWrapper>
  )
}
export { Message }

import React from "react"
import { ChevronRight } from "./ChevronRight"
import { ChevronLeft } from "./ChevronLeft"
import { Heart } from "./Heart"
import { Message } from "./Message"

import { Facebook } from "./Facebook"
import { Instagram } from "./Instagram"
import { BigHeart, PiggyBank, Thumb, Phone } from "./FooterIcons"
import { ToTop } from "./ToTop"
import { KidOutline } from "./KidOutline"
import { HandShake } from "./AboutUs/HandShake"
import { Gift } from "./AboutUs/Gift"
import { GrayHeart } from "./AboutUs/GrayHeart"
import { Envelop } from "./Envelop"

const Icon = ({ type, color, background, width, height }) => {
  switch (type) {
    case "chevronRight":
      return <ChevronRight color={color} />
    case "chevronLeft":
      return <ChevronLeft color={color} />
    case "heart":
      return <Heart width={width} height={height} color={color} />
    case "message":
      return <Message color={color} />
    case "facebook":
      return <Facebook color={color} background={background} />
    case "instagram":
      return <Instagram color={color} background={background} />
    case "scrollTop":
      return <ToTop color={color} background={background} />
    case "bigheart":
      return <BigHeart color={color} />
    case "piggybank":
      return <PiggyBank color={color} />
    case "thumb":
      return <Thumb color={color} />
    case "phone":
      return <Phone color={color} />
    case "kidOutline":
      return <KidOutline color={color} />
    case "handShake":
      return <HandShake color={color} />
    case "grayheart":
      return <GrayHeart color={color} />
    case "gift":
      return <Gift color={color} />
    case "envelop":
      return <Envelop color={color} />
    default:
      return null
  }
}
export { Icon }

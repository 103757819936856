// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-podopieczni-js": () => import("./../../../src/pages/podopieczni.js" /* webpackChunkName: "component---src-pages-podopieczni-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-wesprzyj-nas-js": () => import("./../../../src/pages/wesprzyj-nas.js" /* webpackChunkName: "component---src-pages-wesprzyj-nas-js" */),
  "component---src-pages-wydarzenia-akcja-mikolajkowa-2022-js": () => import("./../../../src/pages/wydarzenia/akcja-mikolajkowa-2022.js" /* webpackChunkName: "component---src-pages-wydarzenia-akcja-mikolajkowa-2022-js" */),
  "component---src-pages-wydarzenia-akcja-podaruj-dzieciom-ksiazke-js": () => import("./../../../src/pages/wydarzenia/akcja-podaruj-dzieciom-ksiazke.js" /* webpackChunkName: "component---src-pages-wydarzenia-akcja-podaruj-dzieciom-ksiazke-js" */),
  "component---src-pages-wydarzenia-alternatywne-formy-wsparcia-js": () => import("./../../../src/pages/wydarzenia/alternatywne-formy-wsparcia.js" /* webpackChunkName: "component---src-pages-wydarzenia-alternatywne-formy-wsparcia-js" */),
  "component---src-pages-wydarzenia-aplikacja-fani-mani-js": () => import("./../../../src/pages/wydarzenia/aplikacja-fani-mani.js" /* webpackChunkName: "component---src-pages-wydarzenia-aplikacja-fani-mani-js" */),
  "component---src-pages-wydarzenia-charytatywny-bieg-piatka-dla-fundacji-js": () => import("./../../../src/pages/wydarzenia/charytatywny-bieg-piatka-dla-fundacji.js" /* webpackChunkName: "component---src-pages-wydarzenia-charytatywny-bieg-piatka-dla-fundacji-js" */),
  "component---src-pages-wydarzenia-final-akcji-mikolaj-2021-js": () => import("./../../../src/pages/wydarzenia/final-akcji-mikolaj-2021.js" /* webpackChunkName: "component---src-pages-wydarzenia-final-akcji-mikolaj-2021-js" */),
  "component---src-pages-wydarzenia-final-akcji-mikolaj-2022-js": () => import("./../../../src/pages/wydarzenia/final-akcji-mikolaj-2022.js" /* webpackChunkName: "component---src-pages-wydarzenia-final-akcji-mikolaj-2022-js" */),
  "component---src-pages-wydarzenia-fundacja-x-fosa-js": () => import("./../../../src/pages/wydarzenia/fundacja-x-fosa.js" /* webpackChunkName: "component---src-pages-wydarzenia-fundacja-x-fosa-js" */),
  "component---src-pages-wydarzenia-koledowanie-2022-js": () => import("./../../../src/pages/wydarzenia/koledowanie-2022.js" /* webpackChunkName: "component---src-pages-wydarzenia-koledowanie-2022-js" */),
  "component---src-pages-wydarzenia-kurs-prawa-jazdy-oli-js": () => import("./../../../src/pages/wydarzenia/kurs-prawa-jazdy-oli.js" /* webpackChunkName: "component---src-pages-wydarzenia-kurs-prawa-jazdy-oli-js" */),
  "component---src-pages-wydarzenia-licytacja-charytatywna-js": () => import("./../../../src/pages/wydarzenia/licytacja-charytatywna.js" /* webpackChunkName: "component---src-pages-wydarzenia-licytacja-charytatywna-js" */),
  "component---src-pages-wydarzenia-licytacje-charytatywne-js": () => import("./../../../src/pages/wydarzenia/licytacje-charytatywne.js" /* webpackChunkName: "component---src-pages-wydarzenia-licytacje-charytatywne-js" */),
  "component---src-pages-wydarzenia-listy-do-swietego-mikolaja-js": () => import("./../../../src/pages/wydarzenia/listy-do-swietego-mikolaja.js" /* webpackChunkName: "component---src-pages-wydarzenia-listy-do-swietego-mikolaja-js" */),
  "component---src-pages-wydarzenia-makramy-js": () => import("./../../../src/pages/wydarzenia/makramy.js" /* webpackChunkName: "component---src-pages-wydarzenia-makramy-js" */),
  "component---src-pages-wydarzenia-mikolajki-2020-js": () => import("./../../../src/pages/wydarzenia/mikolajki-2020.js" /* webpackChunkName: "component---src-pages-wydarzenia-mikolajki-2020-js" */),
  "component---src-pages-wydarzenia-mikolajki-2021-js": () => import("./../../../src/pages/wydarzenia/mikolajki-2021.js" /* webpackChunkName: "component---src-pages-wydarzenia-mikolajki-2021-js" */),
  "component---src-pages-wydarzenia-nasz-zespol-js": () => import("./../../../src/pages/wydarzenia/nasz-zespol.js" /* webpackChunkName: "component---src-pages-wydarzenia-nasz-zespol-js" */),
  "component---src-pages-wydarzenia-nasza-fundacja-js": () => import("./../../../src/pages/wydarzenia/nasza-fundacja.js" /* webpackChunkName: "component---src-pages-wydarzenia-nasza-fundacja-js" */),
  "component---src-pages-wydarzenia-nasze-cele-na-rok-2022-js": () => import("./../../../src/pages/wydarzenia/nasze-cele-na-rok-2022.js" /* webpackChunkName: "component---src-pages-wydarzenia-nasze-cele-na-rok-2022-js" */),
  "component---src-pages-wydarzenia-nasze-fundacyjne-projekty-js": () => import("./../../../src/pages/wydarzenia/nasze-fundacyjne-projekty.js" /* webpackChunkName: "component---src-pages-wydarzenia-nasze-fundacyjne-projekty-js" */),
  "component---src-pages-wydarzenia-nasze-plany-na-2023-js": () => import("./../../../src/pages/wydarzenia/nasze-plany-na-2023.js" /* webpackChunkName: "component---src-pages-wydarzenia-nasze-plany-na-2023-js" */),
  "component---src-pages-wydarzenia-niezly-lumpeks-js": () => import("./../../../src/pages/wydarzenia/niezly-lumpeks.js" /* webpackChunkName: "component---src-pages-wydarzenia-niezly-lumpeks-js" */),
  "component---src-pages-wydarzenia-nowi-wolontariusze-js": () => import("./../../../src/pages/wydarzenia/nowi-wolontariusze.js" /* webpackChunkName: "component---src-pages-wydarzenia-nowi-wolontariusze-js" */),
  "component---src-pages-wydarzenia-nowy-grafik-zajec-js": () => import("./../../../src/pages/wydarzenia/nowy-grafik-zajec.js" /* webpackChunkName: "component---src-pages-wydarzenia-nowy-grafik-zajec-js" */),
  "component---src-pages-wydarzenia-nowy-keyboard-js": () => import("./../../../src/pages/wydarzenia/nowy-keyboard.js" /* webpackChunkName: "component---src-pages-wydarzenia-nowy-keyboard-js" */),
  "component---src-pages-wydarzenia-nowy-rok-js": () => import("./../../../src/pages/wydarzenia/nowy-rok.js" /* webpackChunkName: "component---src-pages-wydarzenia-nowy-rok-js" */),
  "component---src-pages-wydarzenia-oboz-jezdzicki-dla-podopiecznych-js": () => import("./../../../src/pages/wydarzenia/oboz-jezdzicki-dla-podopiecznych.js" /* webpackChunkName: "component---src-pages-wydarzenia-oboz-jezdzicki-dla-podopiecznych-js" */),
  "component---src-pages-wydarzenia-obozy-konne-dla-naszych-podopiecznych-js": () => import("./../../../src/pages/wydarzenia/obozy-konne-dla-naszych-podopiecznych.js" /* webpackChunkName: "component---src-pages-wydarzenia-obozy-konne-dla-naszych-podopiecznych-js" */),
  "component---src-pages-wydarzenia-obozy-konne-js": () => import("./../../../src/pages/wydarzenia/obozy-konne.js" /* webpackChunkName: "component---src-pages-wydarzenia-obozy-konne-js" */),
  "component---src-pages-wydarzenia-pierwsze-przejazdzki-i-warsztaty-robienia-pizzy-js": () => import("./../../../src/pages/wydarzenia/pierwsze-przejazdzki-i-warsztaty-robienia-pizzy.js" /* webpackChunkName: "component---src-pages-wydarzenia-pierwsze-przejazdzki-i-warsztaty-robienia-pizzy-js" */),
  "component---src-pages-wydarzenia-pierwszy-turnus-obozow-konnych-js": () => import("./../../../src/pages/wydarzenia/pierwszy-turnus-obozow-konnych.js" /* webpackChunkName: "component---src-pages-wydarzenia-pierwszy-turnus-obozow-konnych-js" */),
  "component---src-pages-wydarzenia-piknik-js": () => import("./../../../src/pages/wydarzenia/piknik.js" /* webpackChunkName: "component---src-pages-wydarzenia-piknik-js" */),
  "component---src-pages-wydarzenia-piknik-rodzinny-js": () => import("./../../../src/pages/wydarzenia/piknik-rodzinny.js" /* webpackChunkName: "component---src-pages-wydarzenia-piknik-rodzinny-js" */),
  "component---src-pages-wydarzenia-podsumowanie-2022-js": () => import("./../../../src/pages/wydarzenia/podsumowanie-2022.js" /* webpackChunkName: "component---src-pages-wydarzenia-podsumowanie-2022-js" */),
  "component---src-pages-wydarzenia-podsumowanie-charytatywnego-biegu-piatka-dla-fundacji-js": () => import("./../../../src/pages/wydarzenia/podsumowanie-charytatywnego-biegu-piatka-dla-fundacji.js" /* webpackChunkName: "component---src-pages-wydarzenia-podsumowanie-charytatywnego-biegu-piatka-dla-fundacji-js" */),
  "component---src-pages-wydarzenia-podsumowanie-obozu-jezdzieckiego-js": () => import("./../../../src/pages/wydarzenia/podsumowanie-obozu-jezdzieckiego.js" /* webpackChunkName: "component---src-pages-wydarzenia-podsumowanie-obozu-jezdzieckiego-js" */),
  "component---src-pages-wydarzenia-podsumowanie-pikniku-js": () => import("./../../../src/pages/wydarzenia/podsumowanie-pikniku.js" /* webpackChunkName: "component---src-pages-wydarzenia-podsumowanie-pikniku-js" */),
  "component---src-pages-wydarzenia-podsumowanie-transportu-js": () => import("./../../../src/pages/wydarzenia/podsumowanie-transportu.js" /* webpackChunkName: "component---src-pages-wydarzenia-podsumowanie-transportu-js" */),
  "component---src-pages-wydarzenia-podsumowanie-wydarzenia-w-fosie-js": () => import("./../../../src/pages/wydarzenia/podsumowanie-wydarzenia-w-fosie.js" /* webpackChunkName: "component---src-pages-wydarzenia-podsumowanie-wydarzenia-w-fosie-js" */),
  "component---src-pages-wydarzenia-podsumowanie-zbiorki-z-polskie-radio-rzeszow-js": () => import("./../../../src/pages/wydarzenia/podsumowanie-zbiorki-z-polskie-radio-rzeszow.js" /* webpackChunkName: "component---src-pages-wydarzenia-podsumowanie-zbiorki-z-polskie-radio-rzeszow-js" */),
  "component---src-pages-wydarzenia-podziekowania-dla-centrum-inwestycji-deweloperskich-rzeszow-js": () => import("./../../../src/pages/wydarzenia/podziekowania-dla-centrum-inwestycji-deweloperskich-rzeszow.js" /* webpackChunkName: "component---src-pages-wydarzenia-podziekowania-dla-centrum-inwestycji-deweloperskich-rzeszow-js" */),
  "component---src-pages-wydarzenia-podziekowania-za-akcje-mikolaj-2021-js": () => import("./../../../src/pages/wydarzenia/podziekowania-za-akcje-mikolaj-2021.js" /* webpackChunkName: "component---src-pages-wydarzenia-podziekowania-za-akcje-mikolaj-2021-js" */),
  "component---src-pages-wydarzenia-polmetek-obozu-jezdzieckiego-juz-za-nami-js": () => import("./../../../src/pages/wydarzenia/polmetek-obozu-jezdzieckiego-juz-za-nami.js" /* webpackChunkName: "component---src-pages-wydarzenia-polmetek-obozu-jezdzieckiego-juz-za-nami-js" */),
  "component---src-pages-wydarzenia-pomoc-dla-dzieci-z-ukrainy-js": () => import("./../../../src/pages/wydarzenia/pomoc-dla-dzieci-z-ukrainy.js" /* webpackChunkName: "component---src-pages-wydarzenia-pomoc-dla-dzieci-z-ukrainy-js" */),
  "component---src-pages-wydarzenia-pomoc-dla-obywateli-ukrainy-js": () => import("./../../../src/pages/wydarzenia/pomoc-dla-obywateli-ukrainy.js" /* webpackChunkName: "component---src-pages-wydarzenia-pomoc-dla-obywateli-ukrainy-js" */),
  "component---src-pages-wydarzenia-pomoc-dla-szpitala-i-sierocinca-w-ukrainie-js": () => import("./../../../src/pages/wydarzenia/pomoc-dla-szpitala-i-sierocinca-w-ukrainie.js" /* webpackChunkName: "component---src-pages-wydarzenia-pomoc-dla-szpitala-i-sierocinca-w-ukrainie-js" */),
  "component---src-pages-wydarzenia-radosc-w-centrum-zabaw-fantazja-js": () => import("./../../../src/pages/wydarzenia/radosc-w-centrum-zabaw-fantazja.js" /* webpackChunkName: "component---src-pages-wydarzenia-radosc-w-centrum-zabaw-fantazja-js" */),
  "component---src-pages-wydarzenia-rozpoczecie-akcji-mikolaj-2021-js": () => import("./../../../src/pages/wydarzenia/rozpoczecie-akcji-mikolaj-2021.js" /* webpackChunkName: "component---src-pages-wydarzenia-rozpoczecie-akcji-mikolaj-2021-js" */),
  "component---src-pages-wydarzenia-rzeszow-dla-ukrainy-nowy-projekt-js": () => import("./../../../src/pages/wydarzenia/rzeszow-dla-ukrainy-nowy-projekt.js" /* webpackChunkName: "component---src-pages-wydarzenia-rzeszow-dla-ukrainy-nowy-projekt-js" */),
  "component---src-pages-wydarzenia-rzeszow-dla-ukrainy-pierwsze-zajecia-js": () => import("./../../../src/pages/wydarzenia/rzeszow-dla-ukrainy-pierwsze-zajecia.js" /* webpackChunkName: "component---src-pages-wydarzenia-rzeszow-dla-ukrainy-pierwsze-zajecia-js" */),
  "component---src-pages-wydarzenia-spotkanie-w-firmie-drewpol-js": () => import("./../../../src/pages/wydarzenia/spotkanie-w-firmie-drewpol.js" /* webpackChunkName: "component---src-pages-wydarzenia-spotkanie-w-firmie-drewpol-js" */),
  "component---src-pages-wydarzenia-szkolenie-z-wizazu-js": () => import("./../../../src/pages/wydarzenia/szkolenie-z-wizazu.js" /* webpackChunkName: "component---src-pages-wydarzenia-szkolenie-z-wizazu-js" */),
  "component---src-pages-wydarzenia-szukamy-korepetytorow-js": () => import("./../../../src/pages/wydarzenia/szukamy-korepetytorow.js" /* webpackChunkName: "component---src-pages-wydarzenia-szukamy-korepetytorow-js" */),
  "component---src-pages-wydarzenia-szukamy-wolontariuszy-js": () => import("./../../../src/pages/wydarzenia/szukamy-wolontariuszy.js" /* webpackChunkName: "component---src-pages-wydarzenia-szukamy-wolontariuszy-js" */),
  "component---src-pages-wydarzenia-ubrania-do-oddania-js": () => import("./../../../src/pages/wydarzenia/ubrania-do-oddania.js" /* webpackChunkName: "component---src-pages-wydarzenia-ubrania-do-oddania-js" */),
  "component---src-pages-wydarzenia-wsparcie-od-centrum-inwestycji-deweloperskich-rzeszow-js": () => import("./../../../src/pages/wydarzenia/wsparcie-od-centrum-inwestycji-deweloperskich-rzeszow.js" /* webpackChunkName: "component---src-pages-wydarzenia-wsparcie-od-centrum-inwestycji-deweloperskich-rzeszow-js" */),
  "component---src-pages-wydarzenia-wspolpraca-z-silownia-coolgym-coolturysta-js": () => import("./../../../src/pages/wydarzenia/wspolpraca-z-silownia-coolgym-coolturysta.js" /* webpackChunkName: "component---src-pages-wydarzenia-wspolpraca-z-silownia-coolgym-coolturysta-js" */),
  "component---src-pages-wydarzenia-wyjscie-do-fly-wall-js": () => import("./../../../src/pages/wydarzenia/wyjscie-do-fly-wall.js" /* webpackChunkName: "component---src-pages-wydarzenia-wyjscie-do-fly-wall-js" */),
  "component---src-pages-wydarzenia-wyjsie-do-muzeum-dobranocek-js": () => import("./../../../src/pages/wydarzenia/wyjsie-do-muzeum-dobranocek.js" /* webpackChunkName: "component---src-pages-wydarzenia-wyjsie-do-muzeum-dobranocek-js" */),
  "component---src-pages-wydarzenia-wywiad-z-vasylem-js": () => import("./../../../src/pages/wydarzenia/wywiad-z-vasylem.js" /* webpackChunkName: "component---src-pages-wydarzenia-wywiad-z-vasylem-js" */),
  "component---src-pages-wydarzenia-zbiorka-dla-ukrainskich-sierocincow-js": () => import("./../../../src/pages/wydarzenia/zbiorka-dla-ukrainskich-sierocincow.js" /* webpackChunkName: "component---src-pages-wydarzenia-zbiorka-dla-ukrainskich-sierocincow-js" */),
  "component---src-pages-wydarzenia-zbiorka-z-polskie-radio-rzeszow-js": () => import("./../../../src/pages/wydarzenia/zbiorka-z-polskie-radio-rzeszow.js" /* webpackChunkName: "component---src-pages-wydarzenia-zbiorka-z-polskie-radio-rzeszow-js" */),
  "component---src-templates-podopieczni-js": () => import("./../../../src/templates/podopieczni.js" /* webpackChunkName: "component---src-templates-podopieczni-js" */)
}


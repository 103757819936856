export const MENU_DATA = [
  {
    title: "O nas",
    to: "/o-nas",
  },
  {
    title: "Podopieczni",
    to: "/podopieczni",
  },
  {
    title: "Współpraca",
    to: "/wesprzyj-nas",
  },
  {
    title: "Usługi",
    to: "/uslugi",
  },
  {
    title: "Patroni",
    to: "/#patroni",
    anchorLink: true,
  },
  {
    title: "Aktualności",
    to: "/aktualnosci",
  },
  {
    title: "Kontakt",
    to: "/kontakt",
  },
]

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import {StateProvider} from "./src/provider/StateProvider"
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";

import "shared/Styles/global.css"
import "shared/Styles/reset.css"
import "swiper/swiper-bundle.min.css"

SwiperCore.use([EffectFade, Navigation, Autoplay, Pagination]);

export const wrapRootElement = ({element}) => {
  return <StateProvider>{element}</StateProvider>
};

import React from "react"

import { Box, Container, Wrapper, TextBox, Title, Text } from "./styles"

export const Cookies = () => {
  return (
    <Box>
      <Container
        buttonWrapperClasses="center-button"
        buttonText="Rozumiem, zamknij"
        contentClasses="cookies-wrapper"
        style={{
          color: "#fff",
          fontSize: "14px",
          position: "relative",
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Wrapper>
          <TextBox>
            <Title>Ta strona wykorzystuje pliki cookies</Title>
            <Text>
              Jeżeli nie blokujesz w przeglądarce tych plików, to zgadzasz się
              na ich używanie oraz zapisywanie w pamięci urządzenia. W
              przeglądarce istnieje możliwość zarządzana plikami cookies.
              Korzystanie z serwisu bez blokowania plików cookies oznacza zgodę
              na ich wykorzystywanie.
            </Text>
          </TextBox>
        </Wrapper>
      </Container>
    </Box>
  )
}

import React, { useState } from "react"

export const StateContext = React.createContext()

export const StateProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false)
  const [fancyBox, setFancyboxOpen] = useState(false)
  return (
    <StateContext.Provider
      value={{
        fancyBox,
        openModal,
        setOpenModal,
        setFancyboxOpen,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

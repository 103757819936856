import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"
const ChevronRight = ({ color = "#fff" }) => {
  return (
    <IconWrapper
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M1.44736 10.9997C1.14023 11.0017 0.892043 10.8992 0.685127 10.6995C0.559562 10.5785 0.435176 10.4561 0.31079 10.3334C-0.102749 9.92521 -0.103633 9.27994 0.312264 8.8729C1.42938 7.77911 2.54767 6.68675 3.66538 5.59383C3.67923 5.58029 3.69249 5.56588 3.70723 5.5535C3.75233 5.51662 3.74526 5.48465 3.70576 5.44634C3.51564 5.26284 3.3273 5.07761 3.13836 4.89296C2.20429 3.97978 1.2708 3.06603 0.335844 2.15343C0.112716 1.93594 -0.00489093 1.67697 0.00365692 1.36759C0.010731 1.11121 0.10181 0.884785 0.282788 0.698117C0.422796 0.553795 0.566636 0.413218 0.713718 0.27581C1.12048 -0.104439 1.77778 -0.0903241 2.18719 0.30865C3.00513 1.10602 3.82101 1.90541 4.63777 2.70364C5.31393 3.36476 5.98862 4.0276 6.66744 4.68612C6.96632 4.97592 7.03794 5.32708 6.984 5.71683C6.95011 5.96169 6.82631 6.15901 6.64858 6.33243C5.16479 7.78055 3.68247 9.23039 2.20045 10.6802C1.98794 10.8891 1.73563 11.0026 1.44736 10.9997Z"
        color={color}
      />
    </IconWrapper>
  )
}
export { ChevronRight }

import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 12px 0;
  }
`

export const SocialLink = styled.a`
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 100%;
  margin: 0 4px;
  transition: 0.2s background-color ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentColor};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: 32px;
    width: 32px;
    padding: 10px;
  }
`

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
`

import PhoneIcon from "assets/img/svg/phone.svg"
import PhoneIconHover from "assets/img/svg/phone_hover.svg"
import {Link} from "gatsby"
import {AnchorLink} from "gatsby-plugin-anchor-links"
import {SectionWrapper} from "shared/Styles/Wrappers"
import styled, {css} from "styled-components"

export const Container = styled(SectionWrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-top: 64px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-top: 48px;
    }
`

export const Title = styled.div`
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 12px;
    text-align: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 30px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 20px;
    }
`

export const StyledSpan = styled.span`
    color: ${({theme}) => theme.colors.accentColor};
`

export const BoxWrapper = styled.div`
    display: flex;
    margin: 92px 0;
    position: relative;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin: 52px 0;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        margin: 28px 0;
    }
`

export const FooterContact = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

export const FooterContactItem = styled.div`
    width: 25%;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 50%;
        margin-bottom: 24px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: 100%;
    }
`

export const FooterLine = styled.div`
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 14px;
    }
`

export const FooterLineContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 18px;
    }
    b {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 700;
        display: block;
        @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
            font-size: 16px;
            margin-bottom: 18px;
        }
        @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
            font-size: 14px;
            margin-bottom: 8px;
        }
    }
`

export const FooterTitle = styled.span`
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 18px;
    display: block;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 16px;
        margin-bottom: 18px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 14px;
        margin-bottom: 8px;
    }
`

export const FooterSpan = styled.span`
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
    display: block;
    text-align: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 14px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 12px;
    }
`

export const FooterMail = styled.a`
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    text-decoration: underline;
    transition: 0.2s color ease;

    &:not(:last-child) {
        margin-bottom: 14px;
    }

    &:hover {
        color: ${({theme}) => theme.colors.accentColor};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 14px;
    }
`

export const FooterPhone = styled.a`
    display: inline-block;
    font-weight: 700;
    font-size: 24px;
    margin-top: 24px;
    position: relative;
    padding-left: 36px;
    transition: 0.2s color ease;
        margin-bottom: 24px;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(${PhoneIcon});
        transition: 0.2s background-image ease;
    }

    &:hover {
        color: ${({theme}) => theme.colors.accentColor};

        &:before {
            background-image: url(${PhoneIconHover});
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 20px;
        padding-left: 24px;
        margin-top: 18px;
        &:before {
            width: 16px;
            height: 16px;
        }
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        margin-top: 12px;
    }
`

export const FooterMenu = styled.div`
    margin: 84px 0 36px;
    margin-top: 84px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-top: 48px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        margin-top: 0px;
    }
`

export const FooterMenuList = styled.ul`
    display: flex;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        flex-wrap: wrap;
    }
`

export const FooterMenuItem = styled.li`
    &:not(:last-child) {
        margin-right: 24px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        &:not(:last-child) {
            margin-right: 18px;
        }
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: 100%;
        text-align: center;
        margin: 0 0 12px 0 !important;
    }
`

const defaultLink = css`
    display: inline-block;
    padding: 4px;
    color: #999999;
    transition: 0.2s color ease;
    font-size: 14px;
    font-weight: 300;

    &:hover {
        color: ${({theme}) => theme.colors.accentColor};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 14px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 12px;
    }
`

export const FooterMenuLink = styled(Link)`
    ${defaultLink}
`

export const FooterMenuAnchor = styled(AnchorLink)`
    ${defaultLink}
`

export const FooterOutsideLink = styled.a`
    ${defaultLink}
`

export const MailTitle = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #a0a0a0;
    margin-bottom: 4px;
`

export const FooterBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 64px;
    width: 100%;
    @media screen and (max-width: 769px) {
        flex-direction: column;
        text-align: center;
    }
`

export const LogoIvBox = styled.a`
    display: inline-block;
    @media screen and (max-width: 769px) {
        margin: 36px 0;
    }
`

export const LogoIv = styled.img`
    width: 100px;
`

export const Copyrights = styled.span`
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    color: #999999;
    margin-bottom: 48px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 12px;
    }
    @media screen and (max-width: 769px) {
        width: 100%;
        text-align: center;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        max-width: 260px;
        margin: 0 auto 48px auto;
    }
`

import React, { useState } from "react"
import { useScrollPosition } from "shared/Hooks"
import { IconWrapper, IconPath, IconCircle } from "shared/Styles/Icons"
import { Container } from "./styles"

export const ToTop = ({ color = "#fff", background = "#D83990" }) => {
  const [scrollTop, setScrollTop] = useState(false)

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -500) {
      setScrollTop(true)
    } else {
      setScrollTop(false)
    }
  })

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <Container className={scrollTop ? "active" : ""} onClick={handleScrollTop}>
      <IconWrapper
        width="50"
        height="50"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <IconCircle cx="35" cy="35" r="35" color={background} />
        <IconPath
          d="M41.2448 37.3464C41.2471 37.6974 41.1329 37.9811 40.9107 38.2176C40.776 38.3611 40.6397 38.5032 40.5031 38.6454C40.0487 39.118 39.3304 39.119 38.8773 38.6437C37.6598 37.367 36.4438 36.0889 35.2272 34.8116C35.2121 34.7957 35.1961 34.7806 35.1823 34.7637C35.1412 34.7122 35.1056 34.7203 35.063 34.7654C34.8587 34.9827 34.6525 35.1979 34.447 35.4139C33.4305 36.4814 32.4133 37.5482 31.3974 38.6167C31.1553 38.8717 30.867 39.0061 30.5226 38.9964C30.2372 38.9883 29.9852 38.8842 29.7774 38.6774C29.6167 38.5174 29.4603 38.353 29.3073 38.1849C28.884 37.72 28.8997 36.9688 29.3439 36.5009C30.2315 35.5661 31.1213 34.6337 32.0099 33.7003C32.7458 32.9275 33.4837 32.1564 34.2167 31.3806C34.5393 31.039 34.9302 30.9572 35.3641 31.0188C35.6367 31.0576 35.8563 31.1991 36.0494 31.4022C37.6614 33.0979 39.2753 34.792 40.8892 36.4858C41.1217 36.7286 41.248 37.017 41.2448 37.3464Z"
          color={color}
        />
      </IconWrapper>
    </Container>
  )
}

import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"
export const Phone = ({ color = "#000" }) => {
  return (
    <IconWrapper
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M15.1561 21C14.5725 20.994 13.9993 20.9084 13.4499 20.7134C12.4583 20.3611 11.4651 20.0108 10.4884 19.6202C9.44495 19.2033 8.511 18.5873 7.62681 17.9011C5.86838 16.5367 4.30998 14.9714 2.96453 13.197C2.09378 12.0491 1.41309 10.7992 0.947364 9.43337C0.77172 8.91788 0.564231 8.41284 0.387094 7.89735C0.116413 7.1092 -0.051767 6.30213 0.0144105 5.46123C0.0756122 4.67854 0.315443 3.9486 0.681659 3.25846C0.918504 2.81214 1.18968 2.3882 1.51758 2.00059C1.82459 1.63736 2.20872 1.403 2.664 1.27911C3.11878 1.15521 3.57456 1.0338 4.04825 1.00544C4.11443 1.00146 4.18061 1.00047 4.24678 0.998476C4.45776 0.99151 4.62992 1.06913 4.74287 1.24826C4.90806 1.50998 5.07077 1.7737 5.22253 2.04338C5.68079 2.85692 6.12812 3.67642 6.59086 4.48747C6.75655 4.77756 6.96006 5.04575 7.13521 5.33086C7.33672 5.65876 7.32279 5.9787 7.076 6.28471C6.76999 6.66436 6.40079 6.97485 6.02661 7.28335C5.66338 7.58289 5.31657 7.90183 4.96578 8.2163C4.90856 8.26755 4.86875 8.3392 4.82148 8.4019C4.6951 8.56858 4.68067 8.75617 4.74884 8.94375C4.81402 9.12139 4.88666 9.29952 4.9817 9.46223C5.48425 10.322 5.98432 11.1833 6.59584 11.975C7.72732 13.4403 9.11605 14.6042 10.7098 15.5321C11.07 15.7421 11.4328 15.9486 11.798 16.1496C11.8945 16.2029 12.004 16.2357 12.111 16.2656C12.309 16.3208 12.4981 16.281 12.6434 16.1412C12.917 15.878 13.1857 15.6083 13.439 15.3257C13.7783 14.947 14.0928 14.5464 14.4346 14.1708C14.566 14.0265 14.7337 13.9086 14.9014 13.8061C15.1258 13.6687 15.3686 13.7031 15.5885 13.8235C15.8164 13.9479 16.0274 14.1026 16.2523 14.2335C16.916 14.6201 17.5818 15.0037 18.249 15.3844C18.6904 15.6361 19.1362 15.8799 19.5786 16.1302C19.6537 16.1725 19.7204 16.2302 19.789 16.2835C19.9323 16.3949 19.997 16.5397 20.0005 16.7243C20.0085 17.1433 19.9269 17.5488 19.8204 17.9479C19.7398 18.2499 19.6492 18.5539 19.5218 18.8385C19.3571 19.2062 19.0481 19.462 18.7302 19.6964C18.0769 20.178 17.3698 20.5596 16.5881 20.79C16.1224 20.9288 15.6437 21.0005 15.1561 21Z"
        color={color}
      />
    </IconWrapper>
  )
}

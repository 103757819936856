import styled, { css } from "styled-components"
import { Link } from "gatsby"

const defaultLink = css`
  display: inline-flex;
  margin: 0 12px;
  border-radius: 30px;
  background-color: ${({ color }) => color};
  width: 160px;
  height: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s box-shadow ease;
  &:hover {
    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 140px;
    height: 140px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 110px;
    height: 110px;
    margin: 0 8px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_small}) {
    width: 80px;
    height: 80px;
    border-radius: 20px;
  }
`

export const Box = styled(Link)`
  ${defaultLink}
`

export const BoxHref = styled.a`
  ${defaultLink}
`

export const BoxIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 44px;
    height: 44px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 36px;
    height: 36px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_small}) {
    width: 26px;
    height: 26px;
  }
`

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const BoxTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  margin-top: 18px;
  color: #fff;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 14px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_small}) {
    font-size: 12px;
    margin-top: 12px;
  }
`
